import * as React from 'react';
import './Dashboard.scss';
import { connect } from 'react-redux';
import {
	getStatistics,
	Action$GetStatistics,
	myStatistics
} from '../../../redux/actions/Statistics';
import { Statistics } from '../../../models/Statistics';
import StatisticsCard from '../../../components/Cards/StatisticsCard';
import User from '../../../redux/entities/User';
import InputSelect from '../../../components/Form/InputSelect';
import { FormGroup } from '../../../components/Form';
import Label from '../../../components/Form/Label';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import InputCheckbox from '../../../components/Form/InputCheckbox';
import Svg from '../../../components/Svg';
import layout from '../../../utils/layout';
import { listUsersActionCreator } from '../../../redux/UserList/UserListActions';
import Table from './Table';

type Props = {
	getStatistics: Action$GetStatistics;
	myStatistics: Action$GetStatistics;
	statistics: Statistics | null;
	userList: User[];
	getUserList: any;
	user: User;
};

const Dashboard = (props: Props) => {
	const { role_id, id } = props.user;
	const {
		getStatistics,
		myStatistics,
		getUserList,
		statistics,
		userList
	} = props;

	/**
	 * Different end point for admin
	 */
	const loadStatistics = (role_id <= 2 || role_id === 6) ? getStatistics : myStatistics;

	React.useEffect(() => {
		loadStatistics().then();
		/**
		 * Get user list if admin
		 */
		(role_id <= 2 || role_id === 6) && getUserList();
	}, []);

	const [focusedInput, setFocusedInput] = React.useState(null) as any;

	const [startDate, setStartDate] = React.useState(
		moment().subtract(7, 'days')
	);

	const [endDate, setEndDate] = React.useState(moment());

	const [datesActive, setDatesActive] = React.useState('');

	const onDatesChange = ({ startDate, endDate }: any) => {
		setStartDate(startDate);
		setEndDate(endDate);
	};

	const [selectedUser, setSelectedUser] = React.useState(role_id == 6 ? id : '');

	function changeUser(e: React.SyntheticEvent) {
		const { value } = e.target as any;
		setSelectedUser(value);
	}

	const [page, setPage] = React.useState(1);
	const [limit, setLimit] = React.useState(20);

	const handlePage = (page: number) => {
		setPage(page);
	};

	const handleLimit = (limit: number) => {
		setLimit(limit);
	};

	const [loading, setLoading] = React.useState(false);

	const fetch = () => {
		setLoading(true);
		loadStatistics({
			user_id: selectedUser,
			date_from: startDate && datesActive && startDate.format('YYYY-MM-DD'),
			date_to: endDate && datesActive && endDate.format('YYYY-MM-DD'),
			page,
			limit
		})
			.then()
			.catch()
			.then(() => setLoading(false));
	};

	React.useEffect(() => {
		fetch();
	}, [selectedUser, startDate, endDate, datesActive, page, limit]);

	if (!statistics) return null;

	const { meta } = statistics.pagination;

	return (
		<div className="page--dashboard">
			<div className="filters">
				{(role_id <= 2 || role_id == 6) && (
					<FormGroup>
						<Label title="Filter by user:" />
						<InputSelect
							value={selectedUser}
							onChange={changeUser}
							className={selectedUser ? 'active' : ''}
							flat
						>
							{(role_id != 6) && (
								<option value="">All users</option>
							)}
							{userList.map((user: User) => {
								const { id, display_name } = user;
								return (
									<option key={id} value={id}>
										{display_name}
									</option>
								);
							})}
						</InputSelect>
					</FormGroup>
				)}
				<div className="dates">
					<FormGroup>
						<div className="date-label">
							<Label title="Filter by dates:" />
							<InputCheckbox
								white
								onChange={(e: any) => setDatesActive(e.target.checked)}
							/>
						</div>
						<DateRangePicker
							startDate={startDate} // momentPropTypes.momentObj or null,
							startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
							startDatePlaceholderText="Start date"
							endDatePlaceholderText="End date"
							endDate={endDate} // momentPropTypes.momentObj or null,
							endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
							onDatesChange={({ startDate, endDate }) =>
								onDatesChange({ startDate, endDate })
							} // PropTypes.func.isRequired,
							focusedInput={focusedInput}
							displayFormat="DD-MM-YYYY"
							onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
							noBorder
							minimumNights={0}
							isOutsideRange={(d: any) => {
								return moment().diff(d, 'days') < 0;
							}}
						/>
					</FormGroup>
				</div>
			</div>
			<StatisticsCard
				title="Contacts added"
				number={statistics.contactsCount}
				middle={
					<div className="flex flex--center--start" style={{ width: '100%' }}>
						{statistics.approvedCount > 0 && (
							<div className="success stats-num">
								<Svg
									width={12}
									color={layout.color.success}
									icon="check-circle"
								/>{' '}
								{statistics.approvedCount}
							</div>
						)}
						{statistics.pendingCount > 0 && (
							<div className="warning stats-num">
								<Svg width={12} color={layout.color.warning} icon="hourglass" />{' '}
								{statistics.pendingCount}
							</div>
						)}
						{statistics.rejectedCount > 0 && (
							<div className="danger stats-num">
								<Svg width={12} color={layout.color.danger} icon="close" />{' '}
								{statistics.rejectedCount}
							</div>
						)}
						{statistics.addedListedCount > 0 && (
							<div className="stats-num" style={{display: 'flex', alignItems: 'center'}}>
								<span  style={{marginRight: 3}}><Svg width={20} icon="registered"/>{' '}</span>
								{statistics.addedListedCount}
							</div>
						)}
					</div>
				}
				topStats={
					<div className="flex flex--center--start" style={{ width: '100%' }}>
						{statistics.topContactAdd && statistics.topContactAdd.length > 0 && (
							<div className="flex flex--column"  style={{color: '#201a50'}}>
								<span>Top users:</span>
								{statistics.topContactAdd.filter((user) => {
									return user.created_by_user != null;
								}).map((user, index) => {
									const displayName = user.created_by_user.display_name;
									const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
									? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
									: displayName;

									return (
										<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
											<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
											{formattedName}
											<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				}
				icon="new-contact"
			/>
			<StatisticsCard
				title="Contacts listed"
				number={selectedUser || (role_id > 2 && role_id != 6) ? statistics.listedByCount : statistics.listedCount}
				topStats={
					<div className="flex flex--center--start" style={{ width: '100%' }}>
						{statistics.topListers && statistics.topListers.length > 0 && (
							<div className="flex flex--column"  style={{color: '#201a50'}}>
								<span>Top users:</span>
								{statistics.topListers.filter((user) => {
									return user.listed_by_user.display_name != null;
								}).map((user, index) => {
									const displayName = user.listed_by_user.display_name;
									const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
									? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
									: displayName;
										
									return (
										<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
											<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
											{formattedName}
											<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				}
				icon="registered"
			/>
			{(role_id <= 3 || role_id == 6) && (
				<StatisticsCard
					title="Emails sent"
					number={statistics.emailsSentCount}
					icon="mail-sent"
					middle={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.emailsSentListedContacts > 0 && (
								<div className="stats-num" style={{display: 'flex', alignItems: 'center'}}>
									<span  style={{marginRight: 3}}><Svg width={20} icon="registered"/>{' '}</span>
									{statistics.emailsSentListedContacts}
								</div>
							)}
						</div>
					}
					topStats={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.topEmailSent && statistics.topEmailSent.length > 0 && (
								<div className="flex flex--column"  style={{color: '#201a50'}}>
									<span>Top users:</span>
									{statistics.topEmailSent.filter((user) => {
										return user.display_name != null;
									}).map((user, index) => {
										const displayName = user.display_name;
										const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
										? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
										: displayName;

										return (
											<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
												<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
												{formattedName}
												<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					}
				/>
			)}
			{(role_id <= 3 || role_id == 6) && (
				<StatisticsCard
					title="Calls done"
					number={statistics.callsCount}
					icon="phone"
					middle={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.callsListedContacts > 0 && (
								<div className="stats-num" style={{display: 'flex', alignItems: 'center'}}>
									<span  style={{marginRight: 3}}><Svg width={20} icon="registered"/>{' '}</span>
									{statistics.callsListedContacts}
								</div>
							)}
						</div>
					}
					topStats={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.topCallers && statistics.topCallers.length > 0 && (
								<div className="flex flex--column"  style={{color: '#201a50'}}>
									<span>Top users:</span>
									{statistics.topCallers.filter((user) => {
										return user.display_name != null;
									}).map((user, index) => {
										const displayName = user.display_name;
										const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
										? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
										: displayName;

										return (
											<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
												<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
												{formattedName}
												<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					}
				/>
			)}
			{(role_id <= 3 || role_id == 6) && (
				<StatisticsCard
					title="SMS sent"
					number={statistics.messagesCount}
					icon="messages"
					middle={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.messagesListedContacts > 0 && (
								<div className="stats-num" style={{display: 'flex', alignItems: 'center'}}>
									<span  style={{marginRight: 3}}><Svg width={20} icon="registered"/>{' '}</span>
									{statistics.messagesListedContacts}
								</div>
							)}
						</div>
					}
					topStats={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.topMessagers && statistics.topMessagers.length > 0 && (
								<div className="flex flex--column"  style={{color: '#201a50'}}>
									<span>Top users:</span>
									{statistics.topMessagers.filter((user) => {
										return user.display_name != null;
									}).map((user, index) => {
										const displayName = user.display_name;
										const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
										? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
										: displayName;

										return (
											<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
												<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
												{formattedName}
												<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					}
				/>
			)}
			{(role_id <= 3 || role_id == 6) && (
				<StatisticsCard
					title="Checks done"
					number={statistics.checkedByUserCount}
					icon="check"
					middle={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.checkedByUserAccepted > 0 && (
								<div className="success stats-num">
									<Svg
										width={12}
										color={layout.color.success}
										icon="check-circle"
									/>{' '}
									{statistics.checkedByUserAccepted}
								</div>
							)}
						</div>
					}
					topStats={
						<div className="flex flex--center--start" style={{ width: '100%' }}>
							{statistics.topCheckers && statistics.topCheckers.length > 0 && (
								<div className="flex flex--column"  style={{color: '#201a50'}}>
									<span>Top users:</span>
									{statistics.topCheckers.filter((user) => {
										return user.checked_by_user != null;
									}).map((user, index) => {
										const displayName = user.checked_by_user.display_name;
										const formattedName = [3, 4, 5].includes(role_id) && displayName.includes(' ')
										? `${displayName.split(' ')[0]} ${displayName.split(' ')[1][0]}.`
										: displayName;

										return (
											<div key={index} style={{marginTop: 10, color: '#375c8e', display: 'flex', flexDirection: 'row'}}>
												<div style={{textAlign: 'right', marginRight: 3, width: 15}}>{index + 1}.</div>
												{formattedName}
												<span style={{color: '#656161', marginLeft: 3}}>({user.count})</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					}
				/>
			)}
			<Table
				onPageChange={handlePage}
				onLimitChange={handleLimit}
				pages={meta.pages}
				page={meta.page}
				loading={loading}
				total={meta.total}
			/>
		</div>
	);
};

function mapStateToProps(store: any): object {
	const { statistics, userList, user } = store;
	return { statistics, userList, user };
}

function mapDispatchToProps(dispatch: any) {
	return {
		getStatistics: (data?: any) => dispatch(getStatistics(data)),
		myStatistics: (data?: any) => dispatch(myStatistics(data)),
		getUserList: () => dispatch(listUsersActionCreator())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
